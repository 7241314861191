function getUserData(){
  const userDataProfile = JSON.parse(localStorage.getItem('user'));
  return userDataProfile;
}

class Functions {

  static canDoit = (id_task) => {
    const userData = getUserData();

    let result = (userData.tasks).includes(id_task);
    return result;
  };

  
}

export default Functions;
