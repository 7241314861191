import {
    Button,
    Td,
    Text,
    Icon,
    Tr,
    useColorModeValue,
  } from "@chakra-ui/react";

  import {
    FaRegEdit,
    FaRegTrashAlt
  } from "react-icons/fa";
  
  import React, { Fragment, useState, useEffect } from "react"; 
  
  function RowUsers(props) {
    const {item, name, email, role, tasks, onEditarClick, onEliminarClick } = props;
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.400");
    const [userDataProfile, setUserDataProfile] =  useState(JSON.parse(localStorage.getItem('user')));
  
    const handleClickEdit = (item) => {
      onEditarClick(item);
    };
  
    const handleClickDelete = (item) => {
      onEliminarClick(item); 
    };
  
    return (
      <Tr>
        <Td minWidth={{ sm: "20px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {name}
          </Text>
        </Td>
        
        <Td minWidth={{ sm: "180px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {email}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {role}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {tasks}
          </Text>
        </Td>
        <Td>
          <Button p="0px" bg="transparent" variant="no-hover"  onClick={() => handleClickEdit(item)}>
            <Text
              fontSize="md"
              color="gray.400"
              fontWeight="bold"
              cursor="pointer"
            >
              <Icon as={FaRegEdit} fontSize="lg" mb="12px" />
            </Text>
          </Button>
          <Button p="0px" bg="transparent" variant="no-hover"  onClick={() => handleClickDelete(item)}>
            <Text
              fontSize="md"
              color="gray.400"
              fontWeight="bold"
              cursor="pointer"
            >
              <Icon as={FaRegTrashAlt} fontSize="lg" mb="12px" />
            </Text>
          </Button>
        </Td>
      </Tr>
    );
  }
  
  export default RowUsers;
  