// Chakra imports
import {
  Button,
  Flex,
  Grid,
  Text,
  useColorModeValue,
  Table,
  Tbody,
  Th,
  Tr,
  FormLabel,
  Thead,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { Fragment, useState, useEffect } from "react";
import RowUsers from "components/Tables/RowUsers";
import { AiOutlinePlus } from "react-icons/ai";
import Modal from "react-modal";
import Select, { components } from "react-select";
import InputText from "components/Form/InputText";
import InputNumber from "components/Form/InputNumber";
import InputAmount from "components/Form/InputAmount";
import AuthApi from "../../api/auth";
import Swal from "sweetalert2";

function Users() {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  const [titlePage, setTitlePage] = useState("Usuarios");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errors, setErrors] = useState({
    name_: false,
    email_: false,
    role_: false,
    tacks_: false,
  });
  const [listUser, setListUser] = useState([]);
  const [listRole, setListRole] = useState([]);
  const [listTasks, setListTasks] = useState([]);
  const [idUser, setIdser] = useState(null);
  const [nameUser, setNameUser] = useState(null);
  const [emailUser, setEmailUser] = useState(null);
  const [selectedOptionRole, setSelectedOptionRole] = useState(null);
  const [selectedTagsTasks, setSelectedTagsTasks] = useState([]);

  const getListUser = async () => {
    try {
      let response = await AuthApi.getListUsers();
      if (response.data.success === true) {
        let arrayData = [];
        response.data.data.forEach((element) => {
          arrayData.push(element);
        });
        setListUser(arrayData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getListRole = async () => {
    try {
      let response = await AuthApi.getListRole();
      if (response.data.success === true) {
        let arrayData = [];
        response.data.data.forEach((element) => {
          let item = { value: element.id, label: element.name };
          arrayData.push(item);
        });
        setListRole(arrayData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getListTasks = async () => {
    try {
      let response = await AuthApi.getListTasks();
      if (response.data.success === true) {
        let arrayData = [];
        response.data.data.forEach((element) => {
          let item = { value: element.id, label: element.name };
          arrayData.push(item);
          // console.log(item)
        });
        setListTasks(arrayData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const customStylesModal = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "20px",
      borderRadius: "10px",
      border: "none",
    },
  };

  const customStylesSelectModal = (isInvalid) => ({
    control: (provided, state) => ({
      ...provided,
      color: "#cbd5e0",
      borderRadius: "15px",
      padding: "4px",
      marginBottom: "10px",
      fontSize: "14px",
      borderColor: isInvalid ? "red" : "#cbd5e0",
      "&:hover": {
        borderColor: isInvalid ? "red" : "#cbd5e0",
      },
    }),
  });

  const saveUser = async () => {
    let newErrors = {
      name_: !nameUser,
      email_: !emailUser,
      role_: !selectedOptionRole.value,
      tacks_: !selectedTagsTasks,
    };
    setErrors(newErrors);

    const formIsValid = Object.values(newErrors).every((error) => !error);

    if (formIsValid) {
      setIsModalOpen(false);

      let arrayTask = [];
      selectedTagsTasks.forEach((element) => {
        let task = element.value;
        arrayTask.push(task);
      });

      let user = {
        id: idUser,
        name: nameUser,
        email: emailUser,
        role_id: selectedOptionRole.value,
        tasks: arrayTask.join(","),
      };

      try {
        let response = await AuthApi.saveUser(user);
        if (response.data.success === true) {
          Swal.fire({
            icon: "success",
            title: "Éxito",
            text: "Se ha guardado el usuario",
            //position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } catch (err) {
        console.log(err);
      }
      console.log(user);

      getListUser();
      setIdser(null);
      setNameUser(null);
      setEmailUser(null);
      setSelectedOptionRole(null);
      setSelectedTagsTasks(null);
    } else {
      console.log("Hay errores en el formulario");
    }
  };

  const handleCaptureValueNameUser = (value) => {
    setNameUser(value);
  };
  const handleCaptureValueEmailUser = (value) => {
    setEmailUser(value);
  };
  const handleCaptureValueRole = (value) => {
    setSelectedOptionRole(value);
  };

  const handleChangeSelectTasks = (selectedOptions) => {
    setSelectedTagsTasks(selectedOptions);
  };

  const handleEditUser = (numberItem) => {
    setIdser(numberItem);
    let user_edit = Object.values(listUser).find(
      (item) => item.id === numberItem
    );
    let role_edit = Object.values(listRole).find(
      (item) => item.value === user_edit.role_id
    );
    let tasks_edit = [];
    user_edit.tasks.split(",").forEach((element) => {
      let item = Object.values(listTasks).find((item) => item.value == element);
      tasks_edit.push(item);
    });

    setNameUser(user_edit.name);
    setEmailUser(user_edit.email);
    setSelectedOptionRole(role_edit);
    setSelectedTagsTasks(tasks_edit);
    setIsModalOpen(true);
  };

  const handleDeleteUser = (numberItem) => {
    const updatedUser = Object.values(listUser).filter(
      (user) => user.id !== numberItem
    );
    setListUser(updatedUser);
    console.log(numberItem);
  };

  useEffect(() => {
    getListUser();
    getListRole();
    getListTasks();
  }, []);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ sm: "1fr" }}>
        <Card my={{ lg: "24px" }} me={{ lg: "24px" }}>
          <Flex direction="column">
            <CardHeader py="12px">
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                {titlePage}
              </Text>
            </CardHeader>
            <CardBody>
              <Flex direction="column" w="100%" mb="8">
                <Flex direction="row" justifyContent="flex-end">
                  <Button
                    bg="blue.500"
                    color="white"
                    fontSize="xs"
                    p="8px 32px"
                    mr="5"
                    display="flex"
                    justifyContent="flex-end"
                    leftIcon={<AiOutlinePlus />}
                    onClick={() => setIsModalOpen(true)}
                  >
                    Agregar usuario
                  </Button>
                </Flex>
                <Flex direction="row">
                  <Table variant="simple" color={textColor}>
                    <Thead>
                      <Tr my=".8rem" pl="0px" color="gray.500">
                        <Th pl="0px" color="gray.500">
                          NOMBRE
                        </Th>
                        <Th pl="0px" color="gray.500">
                          CORREO
                        </Th>
                        <Th pl="0px" color="gray.500">
                          ROL
                        </Th>
                        <Th pl="0px" color="gray.500">
                          TAREAS
                        </Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {listUser.map((row, index) => {
                        return (
                          <RowUsers
                            item={row.id}
                            name={row.name}
                            email={row.email}
                            role={row.role_name}
                            tasks={row.tasks_name}
                            onEditarClick={handleEditUser}
                            onEliminarClick={handleDeleteUser}
                          />
                        );
                      })}
                    </Tbody>
                  </Table>
                </Flex>
              </Flex>
            </CardBody>
          </Flex>
        </Card>
      </Grid>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Formulario de Usuario"
        ariaHideApp={false}
        style={customStylesModal}
      >
        <Flex direction="column" p="20px">
          <CardHeader py="12px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Detalle de usuario
            </Text>
          </CardHeader>
          <CardBody>
            <Flex direction="column" w="100%">
              <Flex direction="column" w="100%" mr="8">
                <InputText
                  label={"Nombre"}
                  placeholder={"Ingrese nombre"}
                  defaultValue={nameUser}
                  error={errors.name_}
                  captureValue={handleCaptureValueNameUser}
                />
              </Flex>
              <Flex direction="column" w="100%" mr="8">
                <InputText
                  label={"Correo"}
                  placeholder={"Ingrese correo"}
                  defaultValue={emailUser}
                  error={errors.email_}
                  captureValue={handleCaptureValueEmailUser}
                />
              </Flex>
              <Flex direction="column" w="100%" mr="8">
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  {"Rol"}
                </FormLabel>
                <Select
                  styles={customStylesSelectModal(errors.role_)}
                  value={selectedOptionRole}
                  onChange={handleCaptureValueRole}
                  options={listRole}
                  placeholder="Seleccione rol"
                />
              </Flex>
              <Flex direction="column" w="100%" mr="8">
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  {"Tareas"}
                </FormLabel>
                <Select
                  isMulti
                  name="tags"
                  styles={customStylesSelectModal(errors.tacks_)}
                  options={listTasks}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Seleccione tareas"
                  value={selectedTagsTasks}
                  onChange={handleChangeSelectTasks}
                />
              </Flex>
              <Flex direction="column" w="100%" mr="8">
                <Button
                  bg="green.500"
                  color="white"
                  mt={4}
                  onClick={() => saveUser()}
                >
                  Guardar
                </Button>
              </Flex>
            </Flex>
          </CardBody>
        </Flex>
      </Modal>
    </Flex>
  );
}

export default Users;
