import { Box, Flex, Icon, Text, useColorModeValue, Button, } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import AuthApi from "../../api/auth";
import Modal from "react-modal";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import {
  FaClipboard,
  FaFolder,
  FaCheckCircle,
  FaBox,
  FaClock,
  FaWrench,
  FaArrowCircleDown,
  FaFile,  
  FaUserTag,
  FaRegTimesCircle,
} from "react-icons/fa";

function TimelineStatus(props) {
  const { idPurchaseRequest } = props; 
  const textColor = useColorModeValue("gray.700", "white.300");
  const bgIconColor = useColorModeValue("white.300", "gray.700");

  const [dataStatus, setDataStatus] = useState([]);
  const [arrayLength, setArrayLength] = useState(0);
  const [isModalCommentOpen, setIsModalCommentOpen] = useState(false);
  const [commentModal, setCommentModal] = useState('');
  const customStylesModal = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      borderRadius: '10px',
      border: 'none',
    }
  };

  const getStatus = async () => {
    try {
      let response = await AuthApi.getStatusByRequest(idPurchaseRequest);
      if (response.data.success === true) {

        let arrayDataStatus = [];
          response.data.data.forEach(element => {
            let icon_ = null;

            switch (element.icon) {
              case 'FaClipboard':
                icon_ = FaClipboard;
                break;
              case 'FaCheckCircle':
                icon_ = FaCheckCircle;
                break;
              case 'FaBox':
                icon_ = FaBox;
                break;
              case 'FaClock':
                icon_ = FaClock;
                break;
              case 'FaWrench':
                  icon_ = FaWrench;
                break;
              case 'FaArrowCircleDown':
                icon_ = FaArrowCircleDown;
                break;
              case 'FaFile':
                icon_ = FaFile;
                break;
              case 'FaRegTimesCircle':
                icon_ = FaRegTimesCircle;
                break;
              case 'FaUserTag':
                icon_ = FaUserTag;
                break;
              case 'FaFolder':
                icon_ = FaFolder;
                break;
              default:
            }

            let item = {
              id: element.id,
              logo: element.icon,
              title: element.name,
              date: element.date,
              user : element.user,
              logo: icon_,
              color: element.color,
              current: element.current,
              comment: element.comment
            };
            arrayDataStatus.push(item);
          });
          setDataStatus(arrayDataStatus);
          setArrayLength(arrayDataStatus.length)  ;
      }
      
    } catch (err) {
      console.log(err);
    }
  };

  const setComentModal = (comment) => {
    setCommentModal(comment)
    setIsModalCommentOpen(true);

  };

  useEffect(() => {
    getStatus()
  }, [idPurchaseRequest])

  return (
    <Flex direction="column">
      {dataStatus.map((row, index) => (
        <Flex alignItems="center" minH="78px" justifyContent="start" mb="5px" key={index} opacity={row.current == "X" ? 1 : 0.3}>
          <Flex direction="column" h="100%">
            <Icon
              as={row.logo}
              bg={bgIconColor}
              color={row.color}
              h={"30px"}
              w={"26px"}
              pe="6px"
              position="relative"
              right={document.documentElement.dir === "rtl" ? "-8px" : ""}
              left={document.documentElement.dir === "rtl" ? "" : "-8px"}
            />
            <Box
              w="2px"
              bg="gray.200"
              h={index === arrayLength - 1 ? "15px" : "100%"}
            ></Box>
          </Flex>
          <Flex direction="column" justifyContent="flex-start" h="100%">
            <Text fontSize="sm" color={textColor} fontWeight="bold">
              {row.title}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {row.user} - {row.date}
            </Text>
            {(row.comment != "") && (
            <Button size="sm" fontSize="sm" bg="blue.300" color="white" fontWeight="normal" onClick={() => setComentModal(row.comment)} mt={2}>
              Ver Comentario  
            </Button>
            )}
          </Flex>
        </Flex>
      ))}
      <Modal
        isOpen={isModalCommentOpen}
        onRequestClose={() => setIsModalCommentOpen(false)}
        contentLabel="Formulario de Rechazo"
        ariaHideApp={false}
        style={customStylesModal}
      >
        <Flex direction="column" p="20px">
          <CardHeader py="12px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Comentario:
            </Text>
          </CardHeader>
          <CardBody>
            <Flex direction="column" w="100%">
              <Flex direction="column" w="100%">
              <Text fontSize="sm" color={textColor} fontWeight="normal">
                {commentModal}
              </Text>
              </Flex>
              <Flex direction="column" w="100%" mr="8">
                <Button size="sm" bg="blue.500" color="white" mt={4} onClick={() => setIsModalCommentOpen(false)}>Cerrar</Button>
              </Flex>
            </Flex>
          </CardBody>
        </Flex>
      </Modal>
    </Flex>
    
  );
}

export default TimelineStatus;
