import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth } from "auth-context/auth.context";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import Swal from 'sweetalert2';
import '../theme/css/general.css';


export const ProtectedRoute = ({ ...rest }) => {
  const history = useHistory();
  let { user } = useAuth();

  useEffect(() => {
    if (!user || !user.token || user.token === "") {
      const timer = setTimeout(() => {
        history.push("/auth/signin");
      }, 3000); // Redirige después de 3 segundos

      // Limpia el temporizador si el componente se desmonta antes de la redirección
      return () => clearTimeout(timer);
    }
  }, [history, user]);


  if (!user || !user.token || user.token === "") {
    return (
      <div className="background-container-inicial">
        <SweetAlert
          showConfirm={false} 
          customClass="custom-sweetalert">
          <div>
            <h2>Bienvenido a Materials Management Ioon</h2>
          </div>
        </SweetAlert>
      </div>
    );
  }

  return <Route {...rest} />;
};
