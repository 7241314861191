import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

function InputText(props) {
  const { label, placeholder, defaultValue,  error, reandoly = false, captureValue} = props; 
  const [valueText, setValueText] = useState(defaultValue);

  const textColor = useColorModeValue("gray.700", "white.300");
  const bgIconColor = useColorModeValue("white.300", "gray.700");

  useEffect(() => {
    setValueText(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    let value = event.target.value;
    setValueText(value);
    props.captureValue(value);
  };

  return (
    <FormControl flex="12" mr={{ base: "2", md: "24px" }}>
      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
        {label}
      </FormLabel>
      <Input
        borderRadius="15px"
        mb="15px"
        fontSize="sm"
        type="text"
        placeholder={placeholder}
        readOnly={reandoly}
        size="lg"
        value={valueText}
        isInvalid={!!error}
        errorBorderColor="red.500"
        onChange={handleChange}
      />
    </FormControl>
  );
}

export default InputText;
