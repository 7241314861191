import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  Spinner 
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import { AiOutlinePlus, AiOutlineDownload, AiOutlineUpload, AiOutlineCloseCircle, AiOutlineCheck } from 'react-icons/ai';
function InputFileButton(props) {
  const {isLoadingSaveWait, captureValue} = props; 
  const fileInputRef = useRef(null);

  const textColor = useColorModeValue("gray.700", "white.300");
  const bgIconColor = useColorModeValue("white.300", "gray.700");

  const uploadFile = () => {
    // Simular el clic en el input file oculto
    fileInputRef.current.click();
  };

  const handleChange = (event) => {
    let inputValue = event.target.files;
    props.captureValue(inputValue);
  };

  return (
    <FormControl flex="12" mr={{ base: "2", md: "24px" }}>
      <Button bg="blue.500" color="white" fontSize="xs" p="8px 32px" mr="5" leftIcon={<AiOutlineUpload />} onClick={() => uploadFile()}>
        {isLoadingSaveWait ? <Spinner size="xs" /> : 'Subir Archivo'}
      </Button>
      <Input
        mb="15px"
        fontSize="sm"
        type="file"
        size="lg"
        ref={fileInputRef}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </FormControl>
  );
}

export default InputFileButton;
