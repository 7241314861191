import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  Textarea,
  useColorModeValue,
  IconButton,
  Tooltip 
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { SearchIcon } from '@chakra-ui/icons';
import '../../theme/css/general.css'; 

function InputFilterTable(props) {
  const { label, nameInput, filter, showFilters, captureValue, showFilter} = props; 
  const [valueText, setValueText] = useState(filter);

  const textColor = useColorModeValue("gray.700", "white.300");
  const bgIconColor = useColorModeValue("white.300", "gray.700");

  useEffect(() => {
    setValueText(filter);
  }, [filter]);

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setValueText(value);
    props.captureValue({name, value});
  };

  const toggleFilter_ = (name) => {
    props.showFilter(name);
  };

  return (
    <>
    <Tooltip label={label}>
        <IconButton
            icon={<SearchIcon />}
            aria-label={label}
            variant="ghost"
            size="sm"
            ml={2}
            onClick={() => toggleFilter_(nameInput)}
        />
    </Tooltip>
    {showFilters && (
      <>
        <br />
        <Input className="search-input" width={200} placeholder="Buscar" name={nameInput} value={valueText} onChange={handleChange} />
      </>
    )}
    </>
  );
}

export default InputFilterTable;
