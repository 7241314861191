import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

function InputAmount(props) {
  const { label, placeholder, defaultValue,  error, captureValue} = props; 
  const [valueAmount, setValueAmount] = useState(defaultValue);

  const textColor = useColorModeValue("gray.700", "white.300");
  const bgIconColor = useColorModeValue("white.300", "gray.700");

  useEffect(() => {
    setValueAmount(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    let inputValue = event.target.value;

    // Remover cualquier caracter que no sea un número o un punto decimal
    inputValue = inputValue.replace(/[^\d.]/g, '');

    // Asegurar que solo haya un punto decimal
    const decimalIndex = inputValue.indexOf('.');
    if (decimalIndex !== -1) {
      const integerPart = inputValue.slice(0, decimalIndex);
      const decimalPart = inputValue.slice(decimalIndex + 1, decimalIndex + 3);
      inputValue = integerPart + '.' + decimalPart;
    }

    // Actualizar el estado del valor del input
    setValueAmount(inputValue);
    props.captureValue(inputValue);
  };

  const handleBlur = () => {
    if (valueAmount) {
      // Convertir el valor a un número con dos decimales al salir del input
      const formattedValue = parseFloat(valueAmount).toFixed(2);
      setValueAmount(formattedValue);
      props.captureValue(formattedValue);
    }
  };

  return (
    <FormControl flex="12" mr={{ base: "2", md: "24px" }}>
      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
        {label}
      </FormLabel>
      <Input
        borderRadius="15px"
        mb="15px"
        fontSize="sm"
        type="text"
        placeholder={placeholder}
        size="lg"
        value={valueAmount}
        isInvalid={!!error}
        errorBorderColor="red.500"
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </FormControl>
  );
}

export default InputAmount;
