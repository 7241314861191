import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

function InputText(props) {
  const { label, placeholder, defaultValue} = props; 
  const textColor = useColorModeValue("gray.700", "white.300");
  const bgIconColor = useColorModeValue("white.300", "gray.700");

  return (
    <FormControl>
      <FormLabel fontSize="sm" fontWeight="normal">
        {label}
      </FormLabel>
      <Input
        borderRadius="15px"
        mb="15px"
        fontSize="30px"
        type="text"
        prefix={'€'}
        placeholder={""}
        readOnly={true}
        size="lg"
        defaultValue={defaultValue}
        textAlign="right"
        border="none" // No visible border
        _focus={{ border: "none", boxShadow: "none" }}
        onChange={(event) => {}}
      />
    </FormControl>
  );
}

export default InputText;
